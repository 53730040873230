.inner-jumbo {
  height: 400px;
  background-image: url("../../assets/bwi-logo-t-w.png");
  background-attachment: scroll;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 250px;
}

.jumbo {
  height: 400px;
  background-image: url("../../assets/geese.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.white-bg {
  background-color: #fff;
}
